import React, { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

export default function Cards(props) {
  const [raised, setRaised] = useState(false);

  const onCardClick = () => {
    if (!props.cardType) props.onClickCard(props.path);
    else {
      if (props.checkAccess(props.cardType)) props.onClickCard(props.path);
      else props.setOpen(true);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        paddingTop: "25px",
        paddingLeft: "25px",
        "& > :not(style)": {
          m: 1,
          width: 128,
          height: 128,
        },
      }}
    >
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: "#be0028",
              fontSize: "18px",
              display: { xs: 'none', md: 'flex'}
            },
          },
        }}
        title={props.hoverMsg}
        arrow
        placement="right"
      >
        <Card
          sx={{
            minWidth: 190,
            minHeight: 150,
            backgroundColor: "#ebebeb",
            textAlign: "left",
          }}
          raised={raised}
          onMouseEnter={() => setRaised(true)}
          onMouseLeave={() => setRaised(false)}
        >
          <CardActionArea onClick={onCardClick} sx={{ height: "100%" }}>
            <CardContent>
              {props.isImage === "true" ? (
                <div style={{ lineHeight: "23px", marginBottom: "30px" }}>
                  <img src={props.image} alt="Navigator" height="22px" />
                </div>
              ) : props.header ? (
                <Typography
                  sx={{
                    fontStyle: "normal",
                    fonSize: "1rem",
                    lineHeight: "23px",
                    marginBottom: "30px",
                    color: "#000",
                    fontWeight: "500"
                  }}
                >
                  {props.header}
                </Typography>
              ) : (
                <></>
              )}

              {props.subHeader ? (
                <Typography
                  sx={{ mb: 1, paddingBottom: "38px", color: "#bfbfc7" }}
                >
                  {props.subHeader}
                </Typography>
              ) : (
                <></>
              )}
              {props.icon}
              {props.bottomText ? (
                <Typography
                  sx={{ mb: 1, color: "#bfbfc7", fontSize: "0.98rem" }}
                >
                  {props.bottomText}
                </Typography>
              ) : (
                <></>
              )}
            </CardContent>
          </CardActionArea>
        </Card>
      </Tooltip>
    </Box>
  );
}
