import React, { useEffect } from "react";
import SubHeader from "../resusableComponent/SubHeader";
import Cards from "../resusableComponent/Cards";
import IconButton from "@mui/material/IconButton";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import "./proDashboardList.css";
import SingleActionDialog from "../resusableComponent/SingleActionDialog";
import navigationImage from "./navigationPage.png";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useMsal } from "@azure/msal-react";
import BottomInfoBar from "../resusableComponent/BottomInfoBar";

const back = "back";
const subHeading = "Pro - Dashboard";

function ProDashboardList(props) {
  const headerAnalytics = "Benchmarking";
  const headerInventory = "Inventory Insight Dashboard";
  const headerTorrentMy = "Market Insights - Torrent MY";
  const headerMarketInsights = "Market Insights";
  const headerNovartis = "Market Insights 2.0";
  const { accounts } = useMsal();
  const userEmail = accounts[0].idTokenClaims.email;
  const [singleOpen, setSingleOpen] = React.useState(false);
  const [msg, setMsg] = React.useState("");

  useEffect(() => {
    if(window.location.hostname === 'connectclient.dksh.com' || window.location.hostname === 'connectclient.vnbizbox.com') {
      window.appInsights.setAuthenticatedUserContext(userEmail, userEmail);
      window.appInsights.trackPageView({ name: 'Pro - Dashboard' });
    }
  }, []);

  const analyticsIcon = (
    <IconButton aria-label="DashBoard" sx={{ padding: "2px" }}>
      <InsertChartIcon sx={{ color: "#be0028", fontSize: "40px" }} />
    </IconButton>
  );

  const checkAccess = (cardType) => {
    // debugger;
    if (cardType === "benchmarkingpowerbi") {
      if (sessionStorage.getItem("benchmarkingPbiAccess") === "true") {
        return true;
      } else {
        setMsg("Please Contact us to get Access to Sales Insight Dashboard with Benchmarking");
        return false;
      }
    }
    if (cardType === "proiid") {

      if (sessionStorage.getItem("proIidAccess") === "true") {
        return true;
      } else {
        setMsg("Please Contact us to get Access to Inventory Insight Dashboard");
        return false;
      }
    }
    if (cardType === "torrentmy") {

      if (sessionStorage.getItem("torrentMyPbiAccess") === "true") {
        return true;
      } else {
        setMsg("Please Contact us to get Access to Market Insights - Torrent MY Dashboard");
        return false;
      }
    }
    if (cardType === "marketinsights") {

      if (sessionStorage.getItem("marketInsightsPbiAccess") === "true") {
        return true;
      } else {
        setMsg("Please Contact us to get Access to Market Insights Dashboard");
        return false;
      }
    }
    if (cardType === "novartis") {

      if (sessionStorage.getItem("novartisPbiAccess") === "true") {
        return true;
      } else {
        setMsg("Please Contact us to get Access to Market Insights 2.0 Dashboard");
        return false;
      }
    }
  };

  const onClickCard = (path) => {
    props.history.push(path);
  };

  const onClickBack = (path) => {
    props.history.push(path);
  };

  return (
    <>
      <SingleActionDialog open={singleOpen} msg={msg} setOpen={setSingleOpen} />
      <div>
        <SubHeader
          back={back}
          subHeading={subHeading}
          isImage="false"
          onClickBack={onClickBack}
          path="/connect_client/navigator"
        />
      </div>
      <Box
        style={{
          backgroundImage: `url(${navigationImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        sx={{ display: { xs: 'none', md: 'flex' } }}>
        <div style={{ height: "45vh" }}></div>
      </Box>
      <Grid
        container
        direction="row"
      >
        {
          sessionStorage.getItem("benchmarkingPbiAccess") === "true" ?
            <Cards
              header={headerAnalytics}
              isImage="false"
              //subHeader={subHeaderAnalytics}
              icon={analyticsIcon}
              path="/connect_client/navigator/pro_dashboard/sales_insight_dashboard_with_benchmarking"
              setOpen={setSingleOpen}
              checkAccess={checkAccess}
              cardType="benchmarkingpowerbi"
              onClickCard={onClickCard}
              hoverMsg="Daily Sales Insight Dashboard with Benchmarking for better Business planning"
            /> : <></>
        }

        {
          sessionStorage.getItem("proIidAccess") === "true" ?
            <Cards
              header={headerInventory}
              isImage="false"
              //subHeader={subHeaderInventory}
              icon={analyticsIcon}
              path="/connect_client/navigator/pro_dashboard/inventory_insight_dashboard"
              setOpen={setSingleOpen}
              checkAccess={checkAccess}
              cardType="proiid"
              onClickCard={onClickCard}
              hoverMsg="Daily Inventory Insights for better Business planning"
            /> : <></>
        }

        {
          sessionStorage.getItem("torrentMyPbiAccess") === "true" && ( window.location.hostname === 'connectclient.dksh.com' || window.location.hostname === 'qaconnectclient.dksh.com' || window.location.hostname === 'devconnectclient.dksh.com') ?
            <Cards
              header={headerTorrentMy}
              isImage="false"
              //subHeader={subHeaderInventory}
              icon={analyticsIcon}
              path="/connect_client/navigator/pro_dashboard/torrent_my_dashboard"
              setOpen={setSingleOpen}
              checkAccess={checkAccess}
              cardType="torrentmy"
              onClickCard={onClickCard}
              hoverMsg="DKSH Market Insights"
            /> : <></>
        }

        {
          sessionStorage.getItem("marketInsightsPbiAccess") === "true" && ( window.location.hostname === 'connectclient.dksh.com' || window.location.hostname === 'qaconnectclient.dksh.com' || window.location.hostname === 'devconnectclient.dksh.com') ?
            <Cards
              header={headerMarketInsights}
              isImage="false"
              //subHeader={subHeaderInventory}
              icon={analyticsIcon}
              path="/connect_client/navigator/pro_dashboard/market_insights_dashboard"
              setOpen={setSingleOpen}
              checkAccess={checkAccess}
              cardType="marketinsights"
              onClickCard={onClickCard}
              hoverMsg="DKSH Market Insights"
            /> : <></>
        }

        {
          sessionStorage.getItem("novartisPbiAccess") === "true" && ( window.location.hostname === 'connectclient.dksh.com' || window.location.hostname === 'qaconnectclient.dksh.com' || window.location.hostname === 'devconnectclient.dksh.com') ?
            <Cards
              header={headerNovartis}
              isImage="false"
              //subHeader={subHeaderInventory}
              icon={analyticsIcon}
              path="/connect_client/navigator/pro_dashboard/novartis_dashboard"
              setOpen={setSingleOpen}
              checkAccess={checkAccess}
              cardType="novartis"
              onClickCard={onClickCard}
              hoverMsg="DKSH Market Insights"
            /> : <></>
        }

      </Grid>
      <br />
      <BottomInfoBar />
    </>
  );
}

export default ProDashboardList;
