import React, { useEffect, useState, useRef } from "react";
import SubHeader from "../resusableComponent/SubHeader";
import { useMsal } from "@azure/msal-react";
import BottomInfoBar from "../resusableComponent/BottomInfoBar";
import { Tooltip } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import { CircularProgress } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Box } from '@mui/material';
import { REACT_APP_JAVA_BASE_URL, REACT_APP_SUBSCRIPTION_KEY } from "../settings"
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import InfoIcon from '@mui/icons-material/Info';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
const back = "back";
const subHeading = "Pending Order Report";

function PendingOrderReport(props) {
  const { accounts } = useMsal();
  const userEmail = accounts[0].idTokenClaims.email;

  const onClickBack = (path) => {
    props.history.push(path);
  };
  /**
   * State to manage sidebar visibility
   */
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(true)
  const isFirstRender = useRef(true);

  const clearFilters = () => {
    setLoading(true);
    setFilterData([])
    setFilters({
      selectedClients: [],
      selectedSubClients: [],
      selectedCustomerID: [],
      selectedMaterialNo: [],
      selectedWarehouse: [],
      selectedSalesOrderNo: [],
      selectedSalesRejection: []
    })
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [tempStartDate, setTempStartDate] = useState("");
  const [tempEndDate, setTempEndDate] = useState("");
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  const getMinDate = (daysBack) => {
    const date = new Date();
    date.setDate(date.getDate() - daysBack);
    return date.toISOString().split("T")[0];
  };


  const handleInformation = () => {
    /**
     * Route to information page
     */
    const path = "/connect_client/navigator/lite_standard/pending_order_report_information"
    props.history.push(path);
  };
  /**
   *  State to manage sidebar visibility
   */
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  const fetchData = async () => {
    try {
      const url = new URL(`${REACT_APP_JAVA_BASE_URL}/api/parquet/dataFromAzure`);
      const headers = {
        "Ocp-Apim-Subscription-Key": REACT_APP_SUBSCRIPTION_KEY,
        "Access-Control-Allow-Origin": "*",
      }
      const params = { email_address: userEmail };

      if (startDate) params.oldDate = startDate;
      if (endDate) params.newDate = endDate;
      url.search = new URLSearchParams(params).toString();

      const response = await fetch(url, {
        method: 'GET',
        headers: headers,
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      const result = await response.json();
      if (result && result.length > 0) {
        const columnKeys = Object.keys(result[0]);
        var columnData = columnKeys.map((key, index) => (
          {
            field: key,
            headerName: key,
            width: 200,
            hide: key === "Warehouse"
          }))
        columnData = columnData.filter((col => !col.hide))
        setColumns(columnData);
        const rowsWithNumbers = result.map((row, index) => ({ id: index + 1, ...row }));
        setData(rowsWithNumbers);
        setFilterData([])
        for (let i = 0; i < rowsWithNumbers.length; i += 1000) {
          const chunk = rowsWithNumbers.slice(i, i + 1000);
          await new Promise(resolve => {
            setFilterData(prevFilterData => {
              resolve();
              setLoading(false)
              return [...prevFilterData, ...chunk];
            });

          });
        }
        setClients([...new Set(result.map(item => item['Client']).filter(Boolean))])
        setsubClients([...new Set(result.map(item => item['Sub Client']).filter(Boolean))])
        setCustomerID([...new Set(result.map(item => item['Customer ID']).filter(Boolean))])
        setMaterialID([...new Set(result.map(item => item['Material']).filter(Boolean))])
        setWarehouse([...new Set(result.map(item => item['Warehouse']).filter(Boolean))])
        setSalesOrderNo([...new Set(result.map(item => item['Sales Order No']).filter(Boolean))])
        setSalesRejection([...new Set(result.map(item => item).filter(Boolean))])
      }
      else if (result && result.length === 0) {
        setColumns([]);
        setData([]);
        setFilterData([])
        setClients([])
        setsubClients([])
        setCustomerID([])
        setMaterialID([])
        setWarehouse([])
        setSalesOrderNo([])
        setSalesRejection([])
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      console.error("Error fetching data: ", error);
    }
  };
    useEffect(() => {
    setStartDate(getMinDate(14))
    setTempStartDate(getMinDate(14))
    setEndDate(getCurrentDate())
    setTempEndDate(getCurrentDate())
    if (window.location.hostname === 'connectclient.dksh.com' || window.location.hostname === 'connectclient.vnbizbox.com') {
      window.appInsights.setAuthenticatedUserContext(userEmail, userEmail);
      window.appInsights.trackPageView({ name: 'Pending Order Report' });
    }
  }, []);

  const [clients, setClients] = React.useState([]); 
  const [subClients, setsubClients] = React.useState([]);
  const [customerID, setCustomerID] = React.useState([]);
  const [materialID, setMaterialID] = React.useState([]);
  const [warehouse, setWarehouse] = React.useState([]);
  const [salesOrderNo, setSalesOrderNo] = React.useState([]);
  const [salesRejection, setSalesRejection] = React.useState([]);

  const [filters, setFilters] = useState({
    selectedClients: [],
    selectedSubClients: [],
    selectedCustomerID: [],
    selectedMaterialNo: [],
    selectedWarehouse: [],
    selectedSalesOrderNo: [],
    selectedSalesRejection: []
  })

  const changeFilters = (event, value, name) => {
    setLoading(true)
    setFilters((prev) => ({
      ...prev,
      [name]: typeof value === 'string' ? value.split(',') : value,
    }))
    setTimeout(() => {
        setLoading(false);
      }, 1000);
  }
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    setFilterData(data.filter(item =>
      ((filters.selectedClients).length === 0 || (filters.selectedClients).includes(item['Client'])) &&
      ((filters.selectedSubClients).length === 0 || (filters.selectedSubClients).includes(item['Sub Client'])) &&
      ((filters.selectedCustomerID).length === 0 || (filters.selectedCustomerID).includes(item['Customer ID'])) &&
      ((filters.selectedMaterialNo).length === 0 || (filters.selectedMaterialNo).includes(item['Material'])) &&
      ((filters.selectedWarehouse).length === 0 || (filters.selectedWarehouse).includes(item['Warehouse'])) &&
      ((filters.selectedSalesOrderNo).length === 0 || (filters.selectedSalesOrderNo).includes(item['Sales Order No'])) &&
      ((filters.selectedSalesRejection).length === 0 || (filters.selectedSalesRejection).includes(item['Sales Rejection']))
    ));
  }, [filters]);

  const selectDate = (event) => {
    const {
      target: { value },
    } = event;
    const {
      target: { name },
    } = event;
    console.log(name, value)
    if (name === 'startDate') {
      setTempStartDate(value);
    } else if (name === 'endDate') {
      setTempEndDate(value)
    }
  }
  const handleSubmitDate = () => {
    setStartDate(tempStartDate);
    setEndDate(tempEndDate);  // Set the confirmed start date
      console.log("Date confirmed:", tempStartDate)  };
  useEffect(() => {
    if (startDate && endDate !== '') {
      if (new Date(startDate) <= new Date(endDate)) {
        setFilters({
          selectedClients: [],
          selectedSubClients: [],
          selectedCustomerID: [],
          selectedMaterialNo: [],
          selectedWarehouse: [],
          selectedSalesOrderNo: [],
          selectedSalesRejection: []
        })
        setLoading(true)
        fetchData()
      }
    }
  }, [startDate, endDate]);

  const handleFilterOpen = (name) => {
    (async () => {
      console.log("handle open filter", name)
      switch (name) {
        case 'selectedClients':
          setsubClients([...new Set(data.map(item => item['Sub Client']).filter(Boolean))])
          setCustomerID([...new Set(data.map(item => item['Customer ID']).filter(Boolean))])
          setMaterialID([...new Set(data.map(item => item['Material']).filter(Boolean))])
          setWarehouse([...new Set(data.map(item => item['Warehouse']).filter(Boolean))])
          setSalesOrderNo([...new Set(data.map(item => item['Sales Order No']).filter(Boolean))])
          setSalesRejection([...new Set(data.map(item => item['Sales Rejection']).filter(Boolean))])
          break;
        case 'selectedSubClients':
          setsubClients([...new Set(
            data.filter(item => (filters.selectedClients).length === 0 || filters.selectedClients.includes(item['Client']))
              .map(item => item['Sub Client'])
              .filter(Boolean)
          )])
          break;
        case 'selectedMaterialNo':
          setMaterialID([...new Set(
            data.filter(item => (filters.selectedClients).length === 0 || filters.selectedClients.includes(item['Client']))
              .filter(item => (filters.selectedSubClients).length === 0 || filters.selectedSubClients.includes(item['Sub Client']))
              .map(item => item['Material'])
              .filter(Boolean)
          )])
          break;
        case 'selectedCustomerID':
          setCustomerID([...new Set(
            data.filter(item => (filters.selectedClients).length === 0 || filters.selectedClients.includes(item['Client']))
              .filter(item => (filters.selectedSubClients).length === 0 || filters.selectedSubClients.includes(item['Sub Client']))
              .filter(item => (filters.selectedMaterialNo).length === 0 || filters.selectedMaterialNo.includes(item['Material']))
              .map(item => item['Customer ID'])
              .filter(Boolean)
          )])
          break;
        case 'selectedWarehouse':
          setWarehouse([...new Set(
            data.filter(item => (filters.selectedClients).length === 0 || filters.selectedClients.includes(item['Client']))
              .filter(item => (filters.selectedSubClients).length === 0 || filters.selectedSubClients.includes(item['Sub Client']))
              .filter(item => (filters.selectedMaterialNo).length === 0 || filters.selectedMaterialNo.includes(item['Material']))
              .filter(item => (filters.selectedCustomerID).length === 0 || filters.selectedCustomerID.includes(item['Customer ID']))
              .map(item => item['Warehouse'])
              .filter(Boolean)
          )])
          break;
        case 'selectedSalesRejection':
          setSalesRejection([...new Set(
            data.filter(item => (filters.selectedClients).length === 0 || filters.selectedClients.includes(item['Client']))
              .filter(item => (filters.selectedSubClients).length === 0 || filters.selectedSubClients.includes(item['Sub Client']))
              .filter(item => (filters.selectedMaterialNo).length === 0 || filters.selectedMaterialNo.includes(item['Material']))
              .filter(item => (filters.selectedCustomerID).length === 0 || filters.selectedCustomerID.includes(item['Customer ID']))
              .filter(item => (filters.selectedWarehouse).length === 0 || filters.selectedWarehouse.includes(item['Warehouse']))
              .map(item => item['Sales Rejection'])
              .filter(Boolean)
          )])
          break;
        case 'selectedSalesOrderNo':
          setSalesOrderNo([...new Set(
            data.filter(item => (filters.selectedClients).length === 0 || filters.selectedClients.includes(item['Client']))
              .filter(item => (filters.selectedSubClients).length === 0 || filters.selectedSubClients.includes(item['Sub Client']))
              .filter(item => (filters.selectedMaterialNo).length === 0 || filters.selectedMaterialNo.includes(item['Material']))
              .filter(item => (filters.selectedCustomerID).length === 0 || filters.selectedCustomerID.includes(item['Customer ID']))
              .filter(item => (filters.selectedWarehouse).length === 0 || filters.selectedWarehouse.includes(item['Warehouse']))
              .filter(item => (filters.selectedSalesRejection).length === 0 || filters.selectedSalesRejection.includes(item['Sales Rejection']))
              .map(item => item['Sales Order No'])
              .filter(Boolean)
          )])
          break;
        default:
          return
      }
    })();
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Tooltip title="Filter columns" placement="top" arrow>
          <GridToolbarColumnsButton className="" sx={{
            '&:hover .MuiSvgIcon-root': {
              color: '#0077B6',
            }
          }}></GridToolbarColumnsButton>
        </Tooltip>
        <Tooltip title="Download CSV" placement="top" arrow>
          <GridToolbarExport printOptions ={{disableToolbarButton: true}} 
          csvOptions={{
            fileName: 'Pending Order Report',
          }} 
          sx={{
            '&:hover .MuiSvgIcon-root': {
              color: '#0077B6', 
            },
          }}>
          </GridToolbarExport>
        </Tooltip>
        <Box sx={{ marginLeft: 'auto' }}>
          <Tooltip title="More Information" placement="top" arrow>
            <InfoIcon
              onClick={handleInformation}
              style={{ color: '#0077B6', fontSize: '30px', cursor: 'pointer', alignContent: 'right' }} />
          </Tooltip>
        </Box>
      </GridToolbarContainer>
    );
  }
  return (
    <div>
      {loading &&
        <div className="loadingBox" >
          <CircularProgress style={{ color: '#be0028' }} />
        </div>
      }
      <div>
        <SubHeader
          back={back}
          subHeading={subHeading}
          isImage="false"
          onClickBack={onClickBack}
          path="/connect_client/navigator/lite_standard"
        />
      </div>
      <div className="dashboard-container">

        <aside className={`sidebar ${isSidebarVisible ? 'visible' : 'hidden'}`}>
          <div className="d-flex mb-2">
            <button
              className="clear-filters-btn p-2" onClick={clearFilters}>
              Clear Filters
            </button>
            <button className="clear-filters-btn p-2"  style={{marginLeft:"3px"}}onClick={handleSubmitDate}>Load Report</button>
          </div>
          <div className="filter-group">
            <label htmlFor="startDate">Sales Order Start Date</label>
            <input
              id="startDate"
              type="date"
              className="date-picker"
              name="startDate"
              value={tempStartDate} // Use the temporary state for input value
              onChange={(e) => setTempStartDate(e.target.value)} // Update tempStartDate on input change
              min={getMinDate(90)}
              max={getCurrentDate()}
            />
          </div>

          <FormControl>
            {/* <div className="filter-group">
              <label>Sales Order Start Date</label>
              <input
                type="date"
                className="date-picker"
                name="startDate"
                onChange={selectDate}
                value={startDate}
                min={getMinDate(90)} 
                max={getCurrentDate()} 
              />
            </div> */}
            <div className="filter-group">
              <label>Sales Order End Date</label>
              <input
                type="date"
                className="date-picker"
                name="endDate"
                onChange={(e) => setTempEndDate(e.target.value)} 
                value={tempEndDate}
                min={getMinDate(90)}
                max={getCurrentDate()}
              />
            </div> 
            <div className="filter-group">
              <label>Client</label>
              <Autocomplete
                multiple
                size="small"
                onOpen={() => handleFilterOpen('selectedClients')}
                options={clients}
                getOptionLabel={(option) => option}
                onChange={(event, newValue) => changeFilters(event, newValue, 'selectedClients')}
                disableClearable
                value={filters.selectedClients}
                renderInput={(params) => <TextField {...params} placeholder="Select" />}
                renderOption={(props, option, { selected }) => (
                  <MenuItem {...props} key={option} value={option}>
                    <ListItemText primary={option} />
                  </MenuItem>
                )}
                MenuProps={{
                  PaperProps: {
                    style: {
                      padding: '4px 0',
                    }
                  }
                }}
              >
              </Autocomplete>
            </div>
            <div className="filter-group">
              <label>Sub Client</label>
              <Autocomplete
                multiple
                size="small"
                onOpen={() => handleFilterOpen('selectedSubClients')}
                options={subClients}
                getOptionLabel={(option) => option}
                onChange={(event, newValue) => changeFilters(event, newValue, 'selectedSubClients')}
                disableClearable
                value={filters.selectedSubClients}
                renderInput={(params) => <TextField {...params} placeholder="Select" />}
                renderOption={(props, option, { selected }) => (
                  <MenuItem {...props} key={option} value={option}>
                    <ListItemText primary={option} />
                  </MenuItem>
                )}
                MenuProps={{
                  PaperProps: {
                    style: {
                      padding: '4px 0'
                    }
                  }
                }}
              >
              </Autocomplete>
            </div>
            <div className="filter-group">
              <label>Material</label>
              <Autocomplete
                multiple
                size="small"
                onOpen={() => handleFilterOpen('selectedMaterialNo')}
                options={materialID}
                getOptionLabel={(option) => option}
                onChange={(event, newValue) => changeFilters(event, newValue, 'selectedMaterialNo')}
                disableClearable
                value={filters.selectedMaterialNo}
                renderInput={(params) => <TextField {...params} placeholder="Select" />}
                renderOption={(props, option, { selected }) => (
                  <MenuItem {...props} key={option} value={option}>
                    <ListItemText primary={option} />
                  </MenuItem>
                )}
                MenuProps={{
                  PaperProps: {
                    style: {
                      padding: '4px 0'
                    }
                  }
                }}
              >
              </Autocomplete>
            </div>
            <div className="filter-group">
              <label>Customer ID</label>
              <Autocomplete
                multiple
                size="small"
                onOpen={() => handleFilterOpen('selectedCustomerID')}
                options={customerID}
                getOptionLabel={(option) => option}
                onChange={(event, newValue) => changeFilters(event, newValue, 'selectedCustomerID')}
                disableClearable
                value={filters.selectedCustomerID}
                renderInput={(params) => <TextField {...params} placeholder="Select" />}
                renderOption={(props, option, { selected }) => (
                  <MenuItem {...props} key={option} value={option}>
                    <ListItemText primary={option} />
                  </MenuItem>
                )}
                MenuProps={{
                  PaperProps: {
                    style: {
                      padding: '4px 0'
                    }
                  }
                }}
              >
              </Autocomplete>
            </div>
            <div className="filter-group">
              <label>Warehouse</label>
              <Autocomplete
                multiple
                size="small"
                onOpen={() => handleFilterOpen('selectedWarehouse')}
                options={warehouse}
                getOptionLabel={(option) => option}
                onChange={(event, newValue) => changeFilters(event, newValue, 'selectedWarehouse')}
                disableClearable
                value={filters.selectedWarehouse}
                renderInput={(params) => <TextField {...params} placeholder="Select" />}
                renderOption={(props, option, { selected }) => (
                  <MenuItem {...props} key={option} value={option}>
                    <ListItemText primary={option} />
                  </MenuItem>
                )}
                MenuProps={{
                  PaperProps: {
                    style: {
                      padding: '4px 0'
                    }
                  }
                }}
              ></Autocomplete>
            </div>
            <div className="filter-group">
              <label>Sales Rejection</label>
              <Autocomplete
                multiple
                size="small"
                onOpen={() => handleFilterOpen('selectedSalesRejection')}
                options={salesRejection}
                getOptionLabel={(option) => option}
                onChange={(event, newValue) => changeFilters(event, newValue, 'selectedSalesRejection')}
                disableClearable
                value={filters.selectedSalesRejection}
                renderInput={(params) => <TextField {...params} placeholder="Select" />}
                renderOption={(props, option, { selected }) => (
                  <MenuItem {...props} key={option} value={option}>
                    <ListItemText primary={option} />
                  </MenuItem>
                )}
                MenuProps={{
                  PaperProps: {
                    style: {
                      padding: '4px 0'
                    }
                  }
                }}
              ></Autocomplete>
            </div>
            <div className="filter-group">
              <label>Sales Order No</label>
              <Autocomplete
                multiple
                size="small"
                onOpen={() => handleFilterOpen('selectedSalesOrderNo')}
                options={salesOrderNo}
                getOptionLabel={(option) => option}
                onChange={(event, newValue) => changeFilters(event, newValue, 'selectedSalesOrderNo')}
                disableClearable
                value={filters.selectedSalesOrderNo}
                renderInput={(params) => <TextField {...params} placeholder="Select" />}
                renderOption={(props, option, { selected }) => (
                  <MenuItem {...props} key={option} value={option}>
                    <ListItemText primary={option} />
                  </MenuItem>
                )}
                MenuProps={{
                  PaperProps: {
                    style: {
                      padding: '4px 0'
                    }
                  }
                }}
              ></Autocomplete>
            </div>
          </FormControl>
        </aside>

        <div onClick={toggleSidebar}>
          {isSidebarVisible ?
            <ArrowBackIosRoundedIcon className={`sidebar-toggle-btn  ${isSidebarVisible ? 'left-13' : 'left-1'}`} sx={{ width: 17, height: 17 }} viewBox="0 0 24 24"></ArrowBackIosRoundedIcon> :
            <ArrowForwardIosRoundedIcon className={`sidebar-toggle-btn  ${isSidebarVisible ? 'left-13' : 'left-1'}`} sx={{ width: 17, height: 17 }} viewBox="0 0 24 24"></ArrowForwardIosRoundedIcon>
          }
        </div>
        <div className="content">
          {filterData &&
            <DataGrid className="custom-cell" sx={{
              marginLeft: !isSidebarVisible ? '25px' : '0',
              transition: 'padding-left 0.3s ease',
            }}
              rowHeight={37}
              localeText={{
                toolbarColumns: "",
                toolbarExport: "",
                noRowsLabel: ''
              }}
              slots={{ toolbar: CustomToolbar }}
              rows={filterData}
              columns={columns}
              initialState={{
                pagination: { paginationModel: { pageSize: 15 } }
              }}
              pageSizeOptions={[15, 25, 50]}
              disableColumnFilter
            />
          }
        </div>
      </div>
      <BottomInfoBar />
    </div>
  );
}

export default PendingOrderReport;
