import React, { useEffect } from "react";
import SubHeader from "../resusableComponent/SubHeader";
import Cards from "../resusableComponent/Cards";
import IconButton from "@mui/material/IconButton";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import InsertChartIcon from '@mui/icons-material/InsertChart';
import "./ConnectClient.css";
import BottomInfoBar from "../resusableComponent/BottomInfoBar";
import navigationImage from "./navigationPage.png";
import navigatorBlack from "./navigatorBlack.png";
import SingleActionDialog from "../resusableComponent/SingleActionDialog";
import Box from "@mui/material/Box";
import { osName} from "react-device-detect";
import { REACT_APP_FIORI_APP_BASE_URL } from "../settings"
import { useMsal } from "@azure/msal-react";

const subHeading = "";

function ConnectClient(props) {
  const headerAnalytics = "Analytical Solution";
  const headerInventory = "Administrative & Operation";
  const [singleOpen, setSingleOpen] = React.useState(false);
  const [msg, setMsg] = React.useState("")
  const { accounts } = useMsal();
  const userEmail = accounts[0].idTokenClaims.email;

  useEffect(() => {
    if(window.location.hostname === 'connectclient.dksh.com' || window.location.hostname === 'connectclient.vnbizbox.com') {
      window.appInsights.setAuthenticatedUserContext(userEmail, userEmail);
      window.appInsights.trackPageView({name: 'ConnectClient | DKSH'});
    }
  }, []);

  const analyticsIcon = (
    <IconButton aria-label="DashBoard" sx={{ padding: "2px" }}>
      <InsertChartIcon sx={{ color: "#be0028", fontSize: "40px" }} />
    </IconButton>
  );

  const trackingIcon = (
    <IconButton aria-label="Track" sx={{ padding: "2px" }}>
      <LocalShippingIcon sx={{ color: "#be0028", fontSize: "40px" }} />
    </IconButton>
  );

  const onClickCard = (path) => {
    props.history.push(path);
  };

  const onClickCardForSAP = (path) => {
    window.open(path, '_blank', 'noopener,noreferrer');
  };

  const checkAccess = (cardType) => {
    // debugger;
    if (cardType === "sapbtp") {
      if (sessionStorage.getItem("btpAccess") === "true") {
        return true;
      } else {
        setMsg("Please Contact us to get Access to Administrative & Operation");
        return false;
      }
    }

    if (cardType === "navigator") {
      if (sessionStorage.getItem("navigatorAccess") === "true") {
        return true;
      } else {
        setMsg("Please Contact us to get Access to Navigator");
        return false;
      }
    }
  };

  return (
    <>
      <SingleActionDialog open={singleOpen} msg={msg} setOpen={setSingleOpen} />
      <div>
        <SubHeader subHeading={subHeading} isImage="false" onClickBack={onClickCard}/>
      </div>
      <Box
        style={{
          backgroundImage: `url(${navigationImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "100%",
          width: "100%",
        }}
        sx={{ display: { xs: 'none', md: 'flex' } }}
      >
        <div className="tileContainer">
          <Cards
            header={headerAnalytics}
            isImage="true"
            image={navigatorBlack}
            //subHeader={subHeaderAnalytics}
            icon={analyticsIcon}
            path="/connect_client/navigator"
            onClickCard={onClickCard}
            hoverMsg="Healthcare Analytics & Insights Solutions"
            setOpen={setSingleOpen}
            checkAccess={checkAccess}
            cardType="navigator"
          />

          {/* <Cards
            header={headerInventory}
            isImage="false"
            // subHeader={subHeaderInventory}
            icon={trackingIcon}
            path="/connect_client/administrative_operation"
            onClickCard={onClickCard}
            hoverMsg="Real time Administrative & operations data"
            setOpen={setSingleOpen}
            checkAccess={checkAccess}
            cardType="sapbtp"
          /> */}

          {
            osName === "Mac OS" || osName === "iOS" ? 
            <Cards
              header={headerInventory}
              isImage="false"
              // subHeader={subHeaderInventory}
              icon={trackingIcon}
              path={REACT_APP_FIORI_APP_BASE_URL}
              onClickCard={onClickCardForSAP}
              hoverMsg="Real time Administrative & operations data"
              setOpen={setSingleOpen}
              checkAccess={checkAccess}
              cardType="sapbtp"
            />
            :
            <Cards
              header={headerInventory}
              isImage="false"
              // subHeader={subHeaderInventory}
              icon={trackingIcon}
              path="/connect_client/administrative_operation"
              onClickCard={onClickCard}
              hoverMsg="Real time Administrative & operations data"
              setOpen={setSingleOpen}
              checkAccess={checkAccess}
              cardType="sapbtp"
            />
          }
          
        </div>
      </Box>
      <Box
        sx={{ display: { xs: 'flex', md: 'none' } }}
      >
        <div className="tileContainer">
          <Cards
            header={headerAnalytics}
            isImage="true"
            image={navigatorBlack}
            //subHeader={subHeaderAnalytics}
            icon={analyticsIcon}
            path="/connect_client/navigator"
            onClickCard={onClickCard}
            hoverMsg="Healthcare Analytics & Insights Solutions"
            setOpen={setSingleOpen}
            checkAccess={checkAccess}
            cardType="navigator"
          />

          {/* <Cards
            header={headerInventory}
            isImage="false"
            // subHeader={subHeaderInventory}
            icon={trackingIcon}
            path="/connect_client/administrative_operation"
            onClickCard={onClickCard}
            hoverMsg="Real time Administrative & operations data"
            setOpen={setSingleOpen}
            checkAccess={checkAccess}
            cardType="sapbtp"
          /> */}

          {
            osName === "Mac OS" || osName === "iOS" ? 
            <Cards
              header={headerInventory}
              isImage="false"
              // subHeader={subHeaderInventory}
              icon={trackingIcon}
              path={REACT_APP_FIORI_APP_BASE_URL}
              onClickCard={onClickCardForSAP}
              hoverMsg="Real time Administrative & operations data"
              setOpen={setSingleOpen}
              checkAccess={checkAccess}
              cardType="sapbtp"
            />
            :
            <Cards
              header={headerInventory}
              isImage="false"
              // subHeader={subHeaderInventory}
              icon={trackingIcon}
              path="/connect_client/administrative_operation"
              onClickCard={onClickCard}
              hoverMsg="Real time Administrative & operations data"
              setOpen={setSingleOpen}
              checkAccess={checkAccess}
              cardType="sapbtp"
            />
          }

        </div>
      </Box>
      <BottomInfoBar />
    </>
  );
}

export default ConnectClient;
