import React, { useState, useEffect } from "react";
import SubHeader from "../resusableComponent/SubHeader";
import BottomInfoBar from "../resusableComponent/BottomInfoBar";
import Button from '@mui/material/Button';
import { REACT_APP_JAVA_BASE_URL, REACT_APP_SUBSCRIPTION_KEY } from "../settings"
import { CircularProgress } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useMsal } from "@azure/msal-react";

function PendingOrderReportInformation(props) {
  const { accounts } = useMsal();
  const userEmail = accounts[0].idTokenClaims.email;
  const [activeButton, setActiveButton] = useState(1);
  const handleClick = (buttonIndex) => {
    setActiveButton(buttonIndex);
  };
  const [pdfData, setPdfData] = useState(null);
  const [loading, setLoading] = useState(false)
  const [columns, setColumns] = useState([]);
  const [columnsTable2, setColumnsTable2] = useState([]);
  const [dataTable2, setDataTable2] = useState([]);
  const [data, setData] = useState([]);
  const fetchData = async () => {
    setLoading(true)
    try {
      const url = new URL(`${REACT_APP_JAVA_BASE_URL}/api/parquet/readCSV`);
      const headers = {
        "Ocp-Apim-Subscription-Key": REACT_APP_SUBSCRIPTION_KEY,
        "Access-Control-Allow-Origin": "*",
      }
      const response = await fetch(url, {
        method: 'GET',
        headers: headers,
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      const result = await response.json();
        if (result && result.SheetData.length > 0) {
          const columnKeys = Object.keys(result.SheetData[0]); 
          const columnData = columnKeys.map((key, index) => ({ id: index + 1, name: key ,width: 100 } ));
          setColumns(columnData);
          setData(result.SheetData);
          
        }
        setLoading(false)
        if(result && result.VersionData.length > 0)
        {
          const columnKeys = Object.keys(result.VersionData[0]); 
          const columnData = columnKeys.map((key, index) => ({ id: index + 1, name: key }));
          setColumnsTable2(columnData);
          setDataTable2(result.VersionData);
        }
        if (result) {
          const arrayBuffer = result.pdfFile;
          const blob = new Blob([Uint8Array.from(atob(arrayBuffer), (c) => c.charCodeAt(0))], { type: 'application/pdf' });
          let pdfLink = URL.createObjectURL(blob);
          setPdfData(pdfLink);
        }
    }
    catch (error) {
    alert("Error fetching data: ", error);
    setLoading(false)
    }
  };
  const onClickBack = (path) => {
    props.history.push(path);
  };
  useEffect(() => {
    fetchData()
    if (window.location.hostname === 'connectclient.dksh.com' || window.location.hostname === 'connectclient.vnbizbox.com') {
      window.appInsights.setAuthenticatedUserContext(userEmail, userEmail);
      window.appInsights.trackPageView({ name: 'Pending Order Report Information' });
    }

  }, [])
  return (
    <>
      <div>
        <SubHeader
          back={'back'}
          subHeading={'Pending Order Report Information'}
          isImage="false"
          onClickBack={onClickBack}
          path="/connect_client/navigator/lite_standard/pending_order_report"
        />
      </div>
      <div>
        <div>
          {loading &&
            <div className="loadingBox" >
              <CircularProgress style={{ color: '#be0028' }} />
            </div>
          }
        </div>
       <div className="custombutton" aria-label="Basic button group"> 
          <Button variant="outlined" className= "custombutton" onClick={() => handleClick(1)}
            sx={{
              borderRadius: 2,
              borderWidth: 2,
              margin : 0.5 ,
              backgroundColor: activeButton === 1 ? '#be0028' : 'white',
              color: activeButton === 1 ? 'white' : 'black',
              '&:hover': { backgroundColor: activeButton === 1 ? '#be0028' : 'white' , borderWidth: 2, },
              transition: 'background-color 0.3s ease ,color 0.3s ease',
            }}>Glossary</Button>
          <Button variant="outlined" className= "custombutton" onClick={() => handleClick(2)}
            sx={{
              fontWeight: 'bold',
              borderRadius: 2,
              borderWidth: 2,
              margin : 0.5 ,
              backgroundColor: activeButton === 2 ? '#be0028' : 'white',
              color: activeButton === 2 ? 'white' : 'black',
              '&:hover': { backgroundColor: activeButton === 2 ? '#be0028' : 'white', borderWidth: 2 },
              transition: 'background-color 0.3s ease ,color 0.3s ease',
            }}>Version Log</Button>
          <Button variant="outlined" className= "custombutton" onClick={() => handleClick(3)}
            sx={{
              fontWeight: 'bold',
              borderRadius: 2,
              borderWidth: 2,
              margin : 0.5 ,
              backgroundColor: activeButton === 3 ? '#be0028' : 'white',
              color: activeButton === 3 ? 'white' : 'black',
              '&:hover': { backgroundColor: activeButton === 3 ? '#be0028' : 'white', borderWidth: 2 },
              transition: 'background-color 0.3s ease ,color 0.3s ease',
            }}>User Manual</Button>
        </div>
        {activeButton === 1 && (
          <TableContainer className="container1">
            <Table >
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} className="header1">
                      {column.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody >
                {data.map((row, rowIndex) => (
                  <tr className="demo" key={rowIndex}>
                    {columns.map((column) => (
                      <td className="demo" key={column.id}>{row[column.name]}</td>
                    ))}
                  </tr>
                ))}

              </TableBody>
            </Table>
          </TableContainer>
        )}

        {activeButton === 2 && (
           <TableContainer className="container1">
           <Table >
             <TableHead>
               <TableRow>
                 {columnsTable2.map((column) => (
                   <TableCell key={column.id} className="header1">
                     {column.name}
                   </TableCell>
                 ))}
               </TableRow>
             </TableHead>
             <TableBody >
               {dataTable2.map((row, rowIndex) => (
                 <tr className="demo" key={rowIndex}>
                   {columnsTable2.map((column) => (
                     <td className="demo" key={column.id}>{row[column.name]}</td>
                   ))}
                 </tr>
               ))}

             </TableBody>
           </Table>
         </TableContainer>
       )}

        {activeButton === 3 && (
         <iframe
         src={pdfData}
         width="100%"
         height="600px"
         title="PDF Viewer"
       ></iframe>
        )}
      </div>

      <BottomInfoBar />
    </>
  );
}


export default PendingOrderReportInformation;
