import React, { useEffect } from "react";
import SubHeader from "../resusableComponent/SubHeader";
import "./avantDashboardList.css";
import SingleActionDialog from "../resusableComponent/SingleActionDialog";
import navigationImage from "./navigationPage.png";
import { useMsal } from "@azure/msal-react";
import BottomInfoBar from "../resusableComponent/BottomInfoBar";
import Grid from "@mui/material/Grid";
import Cards from "../resusableComponent/Cards";
import IconButton from "@mui/material/IconButton";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import Box from "@mui/material/Box";

const back = "back";
const subHeading = "Avant - Dashboard";

function AvantDashboardList(props) {
  
  const headerSalesForecasting = "Sales Forecasting Dashboard";
  const { accounts } = useMsal();
  const userEmail = accounts[0].idTokenClaims.email;
  const [singleOpen, setSingleOpen] = React.useState(false);
  const [msg, setMsg] = React.useState("");

  useEffect(() => {
    if(window.location.hostname === 'connectclient.dksh.com' || window.location.hostname === 'connectclient.vnbizbox.com') {
      window.appInsights.setAuthenticatedUserContext(userEmail, userEmail);
      window.appInsights.trackPageView({ name: 'Avant - Dashboard' });
    }
  }, []);

  const analyticsIcon = (
    <IconButton aria-label="DashBoard" sx={{ padding: "2px" }}>
      <InsertChartIcon sx={{ color: "#be0028", fontSize: "40px" }} />
    </IconButton>
  );

  const checkAccess = (cardType) => {
    if (cardType === "salesforecasting") {
      
      if (sessionStorage.getItem("salesForecastingPbiAccess") === "true") {
        return true;
      } else {
        setMsg("Please Contact us to get Access to Sales Forecasting Dashboard");
        return false;
      }
    }
  };

  const onClickCard = (path) => {
    props.history.push(path);
  };

  const onClickBack = (path) => {
    props.history.push(path);
  };

  return (
    <>
      <SingleActionDialog open={singleOpen} msg={msg} setOpen={setSingleOpen} />
      <div>
        <SubHeader
          back={back}
          subHeading={subHeading}
          isImage="false"
          onClickBack={onClickBack}
          path="/connect_client/navigator"
        />
      </div>
      <Box 
          style={{
            backgroundImage: `url(${navigationImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }} 
          sx={{ display: { xs: 'none', md: 'flex' } }}>
          <div style={{ height: "45vh" }}></div>
      </Box>
        <Grid
          container
          direction="row"
        >
          {
          sessionStorage.getItem("salesForecastingPbiAccess") === "true" ?
          <Cards
            header={headerSalesForecasting}
            isImage="false"
            icon={analyticsIcon}
            path="/connect_client/navigator/avant_dashboard/sales_forecasting_dashboard"
            setOpen={setSingleOpen}
            checkAccess={checkAccess}
            cardType="salesforecasting"
            onClickCard={onClickCard}
            hoverMsg="Sales Forecasting for better Business planning"
          />: <></>
        }
        </Grid>
        <br />
      <BottomInfoBar />
    </>
  );
}

export default AvantDashboardList;
